import React from 'react'
import { NextSeo } from 'next-seo'

const HomePage = () => (
  <>
    <NextSeo title="kanamori-cloud" />
    <div />
  </>
)

export default HomePage
